/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(document).foundation(); // Foundation JavaScript
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  //Scroll down 
  $('.page-scroll').on('click', function(e){
    e.preventDefault();
    window.scrollTo(0, window.innerHeight);
  });
  
  $('backtotop').on('click', function(e){
    e.preventDefault();
    window.scrollTo(0, 0);
  });

  //Animations 
  function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
  }
  // If element is scrolled into view, fade it in
  $(window).on('scroll', function(){
    $('.to-animate').each(function() {
      if (isScrolledIntoView(this) === true) {
        var animation_name = $(this).data('animation');
        $(this).addClass('animate__animated animate__' + animation_name);
        //$(this).classList.add('animate__animated', 'animate__' + animation_name);
      }
    });
  });

})(jQuery); // Fully reference jQuery after this point.

//Init Plyr
const player = new Plyr('#player', {
  speed: { selected: 1, options: [1] },
  vimeo: { byline: false, portrait: false, title: false, speed: false, transparent: false }
});

var theVideo = document.querySelector('#player');;

if(theVideo){
  if(theVideo.dataset.poster){
    player.poster = theVideo.dataset.poster;
  }
}


// Simple Lightbox - Already Installed
if (jQuery('.gallery a').length){
  var lightbox = jQuery('.gallery a').simpleLightbox();
}

/*
// Slick Slideshow - Already Installed
$('.home-slideshow').slick({
  infinite: true,
  cssEase: 'linear',
  autoplay: true,
  arrows: true,
  dots: true
});


// Smooth Scroll
var topOffset = 0;
$(".scroll-to, .scroll-to-li a").not('[href="#"]').not('[href="#0"]').click(function(t){if(location.pathname.replace(/^\//,"")===this.pathname.replace(/^\//,"")&&location.hostname===this.hostname){var e=$(this.hash);(e=e.length?e:$("[name="+this.hash.slice(1)+"]")).length&&(t.preventDefault(),$("html, body").animate({scrollTop:e.offset().top-topOffset},1e3,function(){var t=$(e);if(t.focus(),t.is(":focus"))return!1;t.attr("tabindex","-1"),t.focus()}))}});
*/